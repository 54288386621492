import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import './style/elementTheme.scss'
import './style/public.scss'

import "../public/fontIcon/iconfont.css"
import utils from "@/utils/utils.js";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//全局组件
import globalCom from './components/index.js'

const app = createApp(App);

// 注册icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(globalCom);

app.use(ElementPlus, {
    locale: zhCn,
})

app.config.globalProperties.$utils = utils;

app.use(router).mount('#app');

<template>
  <div class="home">
    <div class="welcome">欢迎回来！<span class="user-name">{{ schoolName }}</span></div>
    <div class="view-container">
      <div class="home-div">
        <div class="div-left">
          <div class="charts-container flex">
            <div class="charts-left">
              <div class="database-count charts-div">
                <div class="count-content">
                  <div class="count-text">{{countData.vdatabaseNum}}</div>
                  <div class="count-tip">数据库资源</div>
                </div>
                <div class="div-footer flex a-c j-s" @click="routerLinkTo('/dbConfig/dbNavManage/dbNavList')">
                  查看详情
                  <i class="iconfont unify-youjiantou4"></i>
                </div>
              </div>
              <div class="user-count charts-div">
                <div class="last-update">昨日新增：{{countData.yesterdayRegisterUserNum}}</div>
                <div class="count-content">
                  <div class="count-text">{{countData.registerUserNum}}</div>
                  <div class="count-tip">注册人数</div>
                </div>
                <div class="div-footer flex a-c j-s" @click="routerLinkTo('/personnelManagement/registeredUsers')">
                  查看详情
                  <i class="iconfont unify-youjiantou4"></i>
                </div>
              </div>
            </div>
            <div class="charts-right">
              <!-- <qrs/> -->
              <monitor />
            </div>
          </div>
          <div class="pools-div">
            <div class="block-title">功能中心</div>
            <div class="pool-items-container">
              <div :class="['pool-item', item.appType]" v-for="item in menuList" :key="item.name" @click="goUrl(item)">
                <div class="icon-box">
                  <el-icon size="20px" color="#fff">
                    <component :is="item.icon" />
                  </el-icon>
                </div>
                <div class="pool-tip">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-right">
          <div class="block-title">待办事项（{{tableData.length || 0}}）</div>
          <div class="right-div">
            <div class="right-main" v-for="card in tableData || 3">
              <div class="date-title">{{formatTime(card.timeStr)}}</div>
              <div class="event-block" v-for="item in card.list || 3">
                <div class="color-block"></div>
                <div class="event-name" v-if="item.businessName">{{item.businessName || "XXX项目"}}</div>
                <div class="person" v-if="item.createdByName">负责人：{{item.createdByName || "XXX负责人" }}</div>
                <div class="type" v-if="item.definitionName">采购类型：{{item.definitionName || "XXX类型"}}</div>
                <div class="todo" v-if="item.taskName">代办节点：{{item.taskName || "XXX节点"}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import qrs from "./qrs";
import monitor from "./monitor";
import { findProcessCalendarList , countHomeVdatabaseAndUserNum} from "@/js/network/main"
import {ref,reactive} from 'vue';
import router from "@/router"
import store from '@/store/index'
export default {
  components:{
    // qrs,
    monitor
  },
  computed:{
    schoolName(){
      let userInfo = store.getters.getUserData;
      let schoolName = userInfo?.schoolName || "";

      if(schoolName && schoolName.indexOf('图书馆') > -1){
        return schoolName
      }

      if (schoolName){
        return schoolName + '图书馆'
      }

      return ''
      
    }
  },
  setup(props, {attrs,slots,emit,expose}) {
    const tableLoading = ref(false);
    const countLoading = ref(false);
    const tableData = ref([]);
    const countData = ref({})


    const menuList = ref([
      {
        name:'代办任务',
        appType:'ERMS',
        url:'http://erm.addup.com.cn/index',
        icon:"Collection",
      },
      {
        name:'采购管理',
        appType:'ERMS',
        url:'http://erm.addup.com.cn/flowable/project/index',
        icon:"ShoppingCart",
      },
      {
        name:'经费管理',
        appType:'ERMS',
        url:'http://erm.addup.com.cn/fund/source/index',
        icon:"Money",
      },
      {
        name:'订购管理',
        appType:'ERS',
        url:'http://data.addup.com.cn/#/databaseManage/orderManage',
        icon:"Notebook",
      },
      {
        name:'列表管理',
        appType:'ERS',
        url:'http://data.addup.com.cn/#/databaseManage/resourceManage',
        icon:"Document",
      },
      {
        name:'库商管理',
        appType:'ERS',
        url:'http://data.addup.com.cn/#/databaseManage/cms',
        icon:"OfficeBuilding",
      },
      {
        name:'COUNT管理',
        appType:'ERS',
        url:'http://data.addup.com.cn/#/databaseManage/reportManage',
        icon:"DataAnalysis",
      },
      {
        name:'分析报告',
        appType:'ERS',
        url:'http://ers.addup.com.cn/#/report/cooperateins/enjournal/reportPreview',
        icon:"TakeawayBox",
      },
      {
        name:'标杆分析',
        appType:'ERS',
        url:'http://ers.addup.com.cn/#/compare/cooperateins/enjournal/example',
        icon:"PieChart",
      },
      {
        name:'馆藏概览',
        appType:'ERS',
        url:'http://ers.addup.com.cn/#/overview/cooperateins/abstract',
        icon:"DataLine",
      },
    ])

    const getProcessCalendarList = function (){
      tableLoading.value = true;
      findProcessCalendarList().then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        tableData.value = res.data;
        tableLoading.value = false;
      }).catch(error => {
        tableLoading.value = false;
      });
    }

    const getCountHomeVdatabaseAndUserNum = function (){
      countLoading.value = true;
      countHomeVdatabaseAndUserNum().then(res => {
        if (!res || !res.data) {
          countLoading.value = false;
          return;
        }
        countData.value = res.data;
        countLoading.value = false;
      }).catch(error => {
        countLoading.value = false;
      });
    }

    const goUrl = (item) => {
      window.open(item.url);
    }

    //格式化时间
    const formatTime = function (timeObj){
      // let date = new Date(time);
      // let year = date.getFullYear();
      // let month = date.getMonth();
      // let day = date.getDate();
      return timeObj.monthValue+"月"+timeObj.dayOfMonth+"日,"+timeObj.year;
    }

    const routerLinkTo = function (path){
      router.push(path);
    }


    getProcessCalendarList();
    getCountHomeVdatabaseAndUserNum();
    // router.push('dbConfig');

    return {
      tableLoading,
      tableData,
      countLoading,
      countData,
      getProcessCalendarList,
      getCountHomeVdatabaseAndUserNum,
      formatTime,
      routerLinkTo,
      menuList,
      goUrl
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  .welcome {
    text-align: left;
    height: 70px;
    position: absolute;
    top: 0;
    font-size: 20px;
    line-height: 70px;
    padding-left: 20px;
    color: #333333;

    .user-name {
      font-weight: 600;
    }
  }
}

.home-div{
  display: flex;
  height: calc(100vh - 140px);
  //border: 1px solid #ccc;
}
.div-left{
  flex: 1;
  margin-right: 20px;
  //border: 1px solid red;
  min-height: 600px;
}
.charts-container{

}
.charts-left{
  display: flex;
}
.charts-div{
  width: 258px;
  height: 330px;
  background-size: contain;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  margin-right: 17px;
  position: relative;
}
.count-content{
  margin-top: 65px;
  margin-left: 18px;
}
.count-text{
  font-size: 24px;
  color: #ffffff;
}
.count-tip{
  margin-top: 4px;
  font-size: 16px;
  color: #ffffff;
}
.div-footer{
  position: absolute;
  width: 100%;
  height: 44px;
  bottom: 0;
  left: 0;
  padding: 0 15px 0 18px;
  box-sizing: border-box;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  background: rgba(255,255,255,0.1);
  .iconfont{
    font-size: 12px;
  }
}
.database-count{
  background-image: url("https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/database-count-bag.png");

}
.user-count{
  background-image: url("https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/user-count-bag.png");
  position: relative;
}
.last-update{
  padding: 2px 3px;
  background-color: #EB3F3F;
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 4px;
}

.charts-right{
  width: calc(100% - 258px - 20px);
}

.block-title{
  font-size: 22px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 20px;
}

.pools-div{
  border-radius: 8px;
  height: calc(100% - 355px);
  margin-top: 20px;
}
.pool-items-container{
  display: flex;
  flex-wrap: wrap;
}
.pool-item{
  width: 100px;
  height: 100px;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 0;
  border-radius: 6px;
  box-sizing: border-box;
  // margin-right: 12px;
  margin: 15px 45px 15px 0;
  cursor: pointer;
  .icon-box{
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &.ERMS{
    background-color: rgba(246, 165, 63, 0.10);
    
    .icon-box{
      width: 42px;
      height: 42px;
      background: linear-gradient( 180deg, #F6A53F 0%, #FF8E00 100%);
      border-radius: 10px 10px 10px 10px;
      // border: 1px solid rgba(0,0,0,0.05);
    }
  }
  &.ERS{
    background-color:rgba(115, 119, 240, 0.10);

    .icon-box{
      width: 42px;
      height: 42px;
      background: linear-gradient( 180deg, #7377F0 0%, #0008FF 100%);
      border-radius: 10px 10px 10px 10px;
      // border: 1px solid rgba(0,0,0,0.05)
    }
  }
  // margin-right: 50px;
  // margin-bottom: 40px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pool-img{
  border: 1px solid blue;
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}
.pool-tip{
 font-size: 14px;
color: #000000;
  user-select: none;
  font-weight: 500;
}

.div-right{
  width: 360px;
  background: #F8F9FD;
  border-radius: 8px;
  padding: 18px 0px 18px 24px;
  box-sizing: border-box;
  border: 1px solid #DCE1F0;
}
.right-div{
  overflow-y: scroll;
  height: calc(100% - 60px);
}
.right-main{
  margin-bottom: 20px;
}
.date-title{
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 10px;
}
.event-block{
  margin-bottom: 15px;
  background: #e2e9fa;
  //border-radius: 4px;
  padding: 15px 10px;
  position: relative;
  overflow: hidden;
  .color-block{
    position: absolute;
    width: 6px;
    //height: calc(100% - 8px);
    height: 100%;
    background: #2054D6;
    left: 0px;
    top: 0px;
  }
}
.event-name{
  font-size: 16px;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 380px;
}
.person{
  font-size: 14px;
  color: #868686;
  margin-top: 10px;
}
.type{
  font-size: 14px;
  color: #868686;
  margin-top: 10px;
}
.todo{
  font-size: 14px;
  color: #868686;
  margin-top: 10px;
}

</style>

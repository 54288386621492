<template>
  <div class="userPermissions">
    <wordScrollList ref="wordScrollList" :keyword-list="state.cardList" @clickKeyWord="typeChange"/>
    <div class="transfer">
      <el-transfer v-loading="cardLoading"
                   :titles="['未授权数据库','已授权数据库']"
                   v-model="authorizedList"
                   :data="transFerData"
                   :props="{key:'dId',label:'dbName'}"
                   filterable
                   :filter-method="filterMethod"
                   filter-placeholder="输入筛选"
                   @change="save"
      />
    </div>
  </div>
</template>

<script>
import {findCardTypeNameList, findAuthorizedDatabaseList, saveAuthorizedList} from "@/js/network/main"
import wordScrollList from "@/views/DbConfig/DbNavManage/UserPermissions/wordScrollList";
import {reactive, ref} from "vue";
import {MessageBox} from "@/js/utils/commonJs";

export default {
  name:'UserPermissions',
  components: {
    wordScrollList
  },
  setup(props, {attrs, slots, emit, expose}) {
    const wordScrollList = ref(null);
    const cardTypeName = ref("");
    const cardLoading = ref(true);
    let state = reactive({
      cardList: [],
    })
    const getCardTypeNameList = function () {
      cardLoading.value = true;
      findCardTypeNameList().then(res => {
        if (!res || !res.data) {
          cardLoading.value = false;
          return;
        }
        cardLoading.value = false;
        state.cardList = res.data.map(r => {
          return {name: r};
        });
        wordScrollList.value.clickKeyWord(state.cardList[0]);
      }).catch(error => {
        cardLoading.value = false;
      });

    }
    const transFerData = ref([]);
    const authorizedList = ref([]);
    const generateData = function () {
      cardLoading.value = true;
      findAuthorizedDatabaseList({cardTypeName: cardTypeName.value}).then(res => {
        if (!res || !res.data) {
          cardLoading.value = false;
          return;
        }
        cardLoading.value = false;
        transFerData.value = [];
        authorizedList.value = [];
        res.data.unauthorizedList.map(r => {
          transFerData.value.push(r);
        })
        res.data.authorizedList.map(r => {
          transFerData.value.push(r);
          authorizedList.value.push(r.dId);
        })

      }).catch(error => {

      });
    }
    const save = function () {
      cardLoading.value = true;
      saveAuthorizedList({cardTypeName: cardTypeName.value, idList: authorizedList.value}).then(res => {
        if (!res.success) {
          cardLoading.value = false;
          return;
        }
        MessageBox.success("保存成功！")
        cardLoading.value = false;
      }).catch(error => {

      });
    }
    const typeChange = function (item) {
      cardTypeName.value = item.name;
      generateData();
    }

    const filterMethod = (query, item) => {
      return item.dbName.includes(query);
    }
    getCardTypeNameList();

    return {
      state,
      cardTypeName,
      cardLoading,
      wordScrollList,
      getCardTypeNameList,
      transFerData,
      authorizedList,
      filterMethod,
      typeChange,
      save
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.userPermissions {
  padding-top: 20px;
  box-sizing: border-box;
}

.transfer {
  margin-top: 20px;
}

:deep(.el-transfer-panel) {
  width: calc(50% - 85px) !important;
}

:deep(.el-transfer-panel__body) {
  height: 560px !important;
}

:deep(.el-button) {
  height: 36px !important;
}

</style>

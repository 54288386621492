<template>
  <div class="detailSetting">
    <div class="flex">
      <div class="left-container" >
        <el-page-header @back="goBack" :title="'返回'">
          <template #content>
            <span class="header-title">{{ params.name }}</span>
          </template>
        </el-page-header>
      </div>
      <div class="right-container flex" style="justify-content: flex-end">
        <el-switch
            v-model="params.openFlag"
            :active-value="1"
            :inactive-value="0"
            active-text="已发布"
            inactive-text="发布"
            inline-prompt
            :before-change="beforeChange"
            @change="openDatabaseHandler"
            active-color="#2054D6"
            size="large"
        />
      </div>
    </div>

    <div class="flex" >
      <div class="left-container">
        <div style="margin: 20px 0">
          <el-tabs
              v-model="activeName"
              class="demo-tabs"
              @tab-click="handleClick"
          >
            <el-tab-pane v-for="(item,index) in menus" :key="index" :name="item.name" :label="item.label"/>
          </el-tabs>
          <div class="left-main">
            <router-view/>
          </div>
        </div>
      </div>
      <div class="right-container right-main">
        <div class="sub-main">
          <div class="sub-header sub-row">订购信息</div>
          <div class="sub-row flex a-c">
            <div class="sub-tip">订购状态：</div>
            <div class="sub-state">{{ params.orderTypeName || "未配置" }}</div>
            <span class="sub-edit">修改</span>
          </div>
          <div class="sub-row flex a-c">
            <div class="sub-tip">有效范围：</div>
            <div class="sub-state">{{ params.contractYearStr || "未配置" }}</div>
            <span class="sub-edit">修改</span>
          </div>
          <div class="sub-row flex a-c">
            <div class="sub-tip">收录年限：</div>
            <div class="sub-state" v-if="isXiuGai">
              <el-input style="width: 200px" show-word-limit maxlength="100" v-model="params.vyearStr" placeholder="请输入收录年限"/>
              <span class="sub-edit" @click="saveYear">保存</span>
            </div>
            <div class="sub-state" v-else>
              <ellipsis class="over-flow-ellipsis" :data="params.vyearStr || '未配置'">
                <!-- <span >{{params.vyearStr || "未配置"}}</span> -->
              </ellipsis>
              <span class="sub-edit" @click="showYear">修改</span>
            </div>
          </div>
          <div class="sub-header sub-row">备忘录</div>
          <el-input
              v-model="params.memorandum"
              @input="changeMemorandum"
              :rows="18"
              show-word-limit
              maxlength="2000"
              type="textarea"
              placeholder="请输入备忘信息"
          />
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {ElMessageBox} from "element-plus";
import {
  findManagerDatabaseDetailsPublicInfoById,
  saveMemorandum,
  saveVyearStr,
  openDatabase
} from "@/js/network/main"
import {debounce, MessageBox} from "@/js/utils/commonJs";

export default {
  name: 'DetailSetting',
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.act = newVal.path;
        const path = newVal.path;
        this.menus.forEach(item => {
          if (path.endsWith(item.name)) {
            this.activeName = item.name;
          }
        })
      },
    }
  },
  data() {
    return {
      isXiuGai:false,
      onceInit: false,
      params: {},
      textarea: "",
      yearRange: "",
      noticeState: true,
      activeName: 'baseInfo',
      menus: [
        {
          name: 'baseInfo',
          label: '基础信息'
        },
        {
          name: 'siteSetting',
          label: '站点管理'
        },
        {
          name: 'navSetting',
          label: '导航设置'
        },
        {
          name: 'childRelate',
          label: '子库关联'
        },
        {
          name: 'notificationSetting',
          label: '提示设置'
        },
        {
          name: 'helpDocument',
          label: '帮助文档'
        },
        {
          name: 'attachment',
          label: '附件管理'
        },
        {
          name: 'catalogingLogs',
          label: '编目日志'
        },
      ]
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let {id, dId} = this.$route.query;
      findManagerDatabaseDetailsPublicInfoById({id, dId}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        this.params = {...res.data,yearCopy:res.data.vyearStr};
        if (!id) {
          this.$router.push({
            path: "/dbConfig/detailSetting",
            query: {
              id: res.data.id,
              dId: dId
            }
          });
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    },
    goBack() {
      // this.$router.push("/dbConfig/dbNavManage/dbNavList");
      this.$router.back();
    },
    handleClick(tab, event) {
      let {id, dId} = this.$route.query;
      this.$router.replace({
        path: "/dbConfig/detailSetting/" + tab.props.name,
        query: {id, dId}
      })
    },
    openDatabaseHandler() {
      if (!this.onceInit) {
        return;
      }
      openDatabase({id: this.params.id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    },
    showYear(){
      this.isXiuGai = true;
    },
    saveYear() {
      if(!this.params.vyearStr){
        MessageBox.warning("请输入数据！");
        return
      }
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let params = {
            id: this.params.id,
            vyear: this.params.vyearStr
          }
          saveVyearStr(params).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            this.isXiuGai = false;
            MessageBox.success("保存成功！");
          }).catch(error => {
            console.log(error, "catchError");
          });
        }
      }).catch(() => {
        //取消保存，就返回回显值
        this.params.vyearStr = this.params.yearCopy;
        this.isXiuGai = false;
      })
    },
    changeMemorandum() {
      let params = {
        id: this.params.id,
        memorandum: this.params.memorandum
      }
      debounce(() => {
        saveMemorandum(params).then(res => {
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          MessageBox.success("保存成功！");
        }).catch(error => {
          //MessageBox.error(error);
          console.log(error, "catchError");
        });
      }, 1000, false)
    },
    beforeChange() {
      this.onceInit = true;
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
          if (aaa) {
            resolve(true);
          }
          reject(false);
        }).catch(() => {
          reject(false);
        })
      })

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.detailSetting {
  //padding: 20px 30px;
  .header-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.left-container {
  flex: 1;
}
.left-main{
  overflow-y: scroll;
  height: calc(100vh - 204px);
//  54  70 40  10  30
}
:deep(.el-page-header__content){
  width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right-container {
  width: 400px;
  flex-shrink: 0;

}
.right-main{
  overflow-y: scroll;
  height: calc(100vh - 150px);
  //    70 40  10  30
}

.sub-main {
  padding: 30px 40px;
}

.sub-header {
  font-size: 16px;
  font-weight: 600;
  color: #2B3348;
}

.sub-row {
  margin-bottom: 20px;
}

.sub-tip {
  width: 70px;
  font-size: 14px;
  color: #cccccc;
  white-space: nowrap;
}

.sub-state {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex: 1;
  width: 0;
  display: flex;
}
.over-flow-ellipsis{
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;
  flex: 1;
}

.sub-edit {
  font-size: 12px;
  margin-left: auto;
  color: #257dd9;
  cursor: pointer;
  user-select: none;
  flex-shrink: 0;
  white-space: nowrap;
}

</style>

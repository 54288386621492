<template>
  <div class="CopyrightNotice">
    <div class="label-title">中文版</div>
    <div>
      <tinyMceEditor ref="cnContentRef" :defaultId="'cnContentRef'" key="cnContentRef"
        @update:modelValue="(val) => updateContent(val, 1) " />
    </div>

    <div class="label-title">英文版</div>
    <div>
      <tinyMceEditor ref="enContentRef" :defaultId="'enContentRef'" key="enContentRef"
        @update:modelValue="(val) => updateContent(val, 2) " />
    </div>

    <div class="button-container flex a-c j-c" style="margin-top: 20px">
      <el-button color="#626aef" @click="submit">保存</el-button>
      <el-button color="#626aef" plain @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script>
import {
  findOneUnifyNoticeBySid, saveManagerDatabaseDetailsBaseInfo,
  updateUnifyNoticeBySid
} from "@/js/network/main"

import tinyMceEditor from "@/components/untils/tinyMceEditor"
import {onMounted, reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessageBox} from "element-plus";

export default {
  name:'CopyrightNotice',
  components: {
    tinyMceEditor
  },

  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();

    let cnState = ref("");
    let enState = ref("");
    let cnStateCopy = "";
    let enStateCopy = "";


    let cnContentRef = ref(null);
    let enContentRef = ref(null);


    const updateContent = (val , type) => {
      if(type == 1){
        cnState.value = val;
      }

      if(type == 2){
        enState.value = val;
      }
    }
    const init = function () {
      // let {id}
      findOneUnifyNoticeBySid().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if(!res || !res.data){
          return;
        }
        cnState.value = res.data.content;
        enState.value = res.data.enContent;
        cnStateCopy = res.data.content;
        enStateCopy = res.data.enContent;
        cnContentRef.value.handleSetContent(cnState.value);
        enContentRef.value.handleSetContent(enState.value);
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let params ={
            content:cnState.value,
            enContent:enState.value
          }
          updateUnifyNoticeBySid(params).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！")
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error,"catchError");
          });
        }
      }).catch(() => {
      })

    }
    const reset = () => {
      cnState.value = cnStateCopy;
      enState.value = enStateCopy;
      cnContentRef.value.init(cnState.value);
      enContentRef.value.init(enState.value);
    }
    onMounted(()=>{
      setTimeout(()=>{
        init();
      },400)

    })

    return {
      updateContent,
      cnContentRef,
      enContentRef,
      submit,
      reset
    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.CopyrightNotice{
  height: calc(100vh - 180px);
  overflow-y: scroll;
  // pedestal 70    padding-top 20   padding-bottom 20  navlist  40   footer  30
}

.label-title{
  padding: 20px 0 12px 0;
  font-weight: 600;
}

</style>

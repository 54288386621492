<template>
  <div>
    <el-table
      :class="[ defaultClassName]"
      :stripe="stripe"
      v-bind="$attrs"
      ref="customTable"
    >
      <!-- 状态项 -->
      <el-table-column
        v-for="item in tableColumns"
        :key="item.id ? item.id : item.prop"
        :show-overflow-tooltip="
          item.showOverflowTooltip === false ? false : true
        "
        :align="item.align || 'center'"
        v-bind="item"
      >
        <template v-if="item.headerTemplate" #header="scope">
          <slot :name="item.headerTemplate" v-bind="scope"></slot>
        </template>
        <template v-if="item.template" #default="scope">
          <slot :name="item.template" v-bind="scope"></slot>
        </template>
      </el-table-column>

      <template #empty>
        <div class="noData">暂无数据</div>
      </template>
    </el-table>
  </div>
</template>
<script setup>
import { ref } from "vue";

const tableColumns = defineModel("tableColumns", {
  required: true,
  type: Array,
  default: () => [],
});

const props = defineProps({
  // 数据列表
  // tableColumns:{
  //   type:Array,
  //   default:() => []
  // },

  // 默认样式
  defaultClassName: {
    type: String,
    default: "my-table",
  },
  stripe: {
    type: Boolean,
    default: true,
  },
});


</script>

<style lang="scss" scoped>
.my-table {
  border: 1px solid #dce1f0;
  border-radius: 10px 10px 10px 10px;
  :deep(.el-table__header) {
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    height: 64px;

    .el-table__cell {
      background: #eef2fe !important;
      color: #6573a2;
    }
  }

  :deep(.el-table__row) {
    height: 64px;

    .el-table__cell {
      color: #6573a2;
    }
  }

  :deep(.el-table__row--striped) {
    .el-table__cell {
      background: #f8f9fd !important;
    }
  }
}
</style>

<!-- 弹窗 达人招募审核列表 -->
<template>
  <custom-dialog
    v-model="visible"
    size="big"
    title="达人招募"
    @open="dialogOpen"
    @close="emit('update')"
    
  >
    <div class="talentRecruitment">
      <!-- header-button -->
      <div class="table-title flex a-c j-s">
        <el-button class="primary-btn" type="primary" @click="showPopularizeDialog">
          招募渠道
          <i class="iconfont unify-link" style="margin-left: 4px"></i>
        </el-button>
       
      </div>
    </div>

    <div>
      <custom-table
        v-model:tableColumns="tableData"
        :data="dataList"
        :height='500'
        v-loading="loading"
      >
        <template #edit="{row}">
            <div class="table-edit-public">
                <span class="btn edit" v-if="row.state === 0" @click="audit(row)">审核</span>
            </div>
        </template>
      </custom-table>
    </div>

    <!-- <div class="flex a-c" style="justify-content: flex-end; margin-top: 20px">
      <span style="margin-right: 12px; color: #6573a2; font-size: 16px"
        >总数：{{ total }}</span
      >
      <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
      <el-pagination
        background
        layout="pager,sizes"
        v-model:page-size="paramsFilter.pageSize"
        :page-sizes="[5, 10, 20]"
        :current-page="paramsFilter.pageNum"
        @current-change="handlerPageNumChange"
        @size-change="handlerPageSizeChange"
        :total="total"
      />
      <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
    </div> -->
    
    <!-- 分页 -->
     <div>
      <customPagination
         v-show="total > 0"
         :total="total"
         v-model:page="paramsFilter.pageNum"
         v-model:limit="paramsFilter.pageSize"
         @pagination="getFindRecruitRecordListPage()"
      />
     </div>

    <!-- 海报 -->
    <posterShare v-model="posterShareDialog"></posterShare>

    <!-- 审核 -->
    <auditDialog
      v-model="auditFlag"
      :id="editId"
      @update="getFindRecruitRecordListPage"   
    />
  </custom-dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import posterShare from "@/views/ResourcePromotion/PromotionAmbassador/components/posterShare.vue";
import auditDialog from "@/views/ResourcePromotion/PromotionAmbassador/components/auditDialog.vue";
import { findRecruitRecordListPage } from "@/js/network/main";
import { MessageBox } from "@/js/utils/commonJs";

// 双向绑定
const visible = defineModel({ type: Boolean, default: false });
const loading = ref(false);
const total = ref(0);
const emit = defineEmits();
const paramsFilter = reactive({
  pageSize: 10, //当前页大小
  pageNum: 1, //当前页
});

// 弹窗
const auditFlag = ref(false);
const editId = ref('');
const posterShareDialog = ref(false);

//列表数据
let dataList = ref([]);

function dialogOpen() {
  init();
}

function init() {
  getFindRecruitRecordListPage();
}

function audit(e){
    // console.log('e: ', e);
    auditFlag.value = true;
    editId.value = e.id;
}

function getFindRecruitRecordListPage() {
  loading.value = true;
  findRecruitRecordListPage(paramsFilter)
    .then((res) => {
      loading.value = false;
      if (!res.success) {
        MessageBox.error(res.msg);
        return;
      }
      dataList.value = res.data.map(v=>{
        return {
            ...v
        }
      });
      total.value = Number(res.count);
    })
    .catch((error) => {
      if (error.message == "Request Canceled!") {
        return;
      }
      loading.value = false;
    });
}



// 列表配置
const tableData = ref([
  // <el-table-column type="index" width="50" />
  {
    label: "序号",
    width: "60px",
    align: "center",
    // prop: "id",
    type:"index"
  },
  {
    label: "姓名",
    minWidth: "120px",
    align: "center",
    prop: "name",
  },
  {
    label: "身份",
    minWidth: "80px",
    align: "center",
    prop: "role",
    showOverflowTooltip: false,
  },
  {
    label: "学院",
    minWidth: "120px",
    align: "center",
    prop: "org",
  },
  {
    label: "申请时间",
    minWidth: "120px",
    align: "center",
    prop: "createdTimeStr",
    showOverflowTooltip: false,
  },
  {
    label: "状态",
    minWidth: "60px",
    align: "center",
    prop: "stateStr",
    showOverflowTooltip: false,
  },
  {
    label: "操作",
    width: "80px",
    align: "center",
    template:'edit',
    showOverflowTooltip: false,
  },
]);

// 招募弹窗
function showPopularizeDialog() {
  posterShareDialog.value = true;
}
</script>

<style lang="scss" scoped>
.talentRecruitment {
  .table-title {
    margin-bottom: 15px;
    justify-content: space-between;
  }

  .title-button {
    width: 110px;
    height: 36px;
    background: #2054d6;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    // margin-center: auto;
    margin-right: 5px;
    cursor: pointer;
    .iconfont {
      margin-right: 5px;
    }

    :last-child {
      margin-right: 0;
    }
  }
}
</style>

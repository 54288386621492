<!-- 异常监控 -->
<template>
  <div class="abnormal-monitoring">
    <div class="input-container flex a-c">
      <el-input v-model="paramsFilter.search" clearable @clear="getTable" placeholder="请输入数据库名称"
        @keyup.enter="getTable" />
      <div class="button-container" @click="getTable">
        <i class="iconfont unify-sousuo"></i>
      </div>
    </div>
    <custom-table v-model:tableColumns="tableData" :data="dataList" max-height="calc(100vh - 330px)"
      v-loading="loading">
      <template #stateHeader>
        <div class="label-header" @click="sortChange(1)">
          <span>订购状态监测</span>
          <el-icon class="hdeader-icon" :class="{ 'active': paramsFilter.abnormalSortType  == 1}">
            <CaretBottom />
          </el-icon>
        </div>
      </template>
      <template #infoHeader>
        <div class="label-header" @click="sortChange(2)">
          <span>信息完整性</span>
          <el-icon class="hdeader-icon" :class="{ 'active': paramsFilter.abnormalSortType == 2 }">
            <CaretBottom />
          </el-icon>
        </div>
      </template>
      <template #linkHeader>
        <div class="label-header" @click="sortChange(3)">
          <span>链接监测</span>
          <el-icon class="hdeader-icon" :class="{ 'active': paramsFilter.abnormalSortType == 3 }">
            <CaretBottom />
          </el-icon>
        </div>
      </template>
      <template #dbName="{ row }">
        <span class="ep-click" @click="goDetail(row)">
          {{ row.dbName }}
        </span>
      </template>
      <template #state="{ row }">
        <div class="button" v-if="row.orderAbnormalMode === null">-</div>
        <div class="button" v-else-if="row.orderAbnormalMode.tipList && row.orderAbnormalMode.tipList.length">

          <el-popover placement="left" :width="220" trigger="hover">
            <template #reference>
              <div class="flex a-c pointer">
                <i :class="[
                    'icon',
                    'iconfont',
                    'unify-dian',
                    stateList[row.orderAbnormalMode.abnormalFlag].class,
                  ]"></i>
                <span>
                  {{ row.orderAbnormalMode.abnormalStr }}
                </span>
              </div>
            </template>
            <div class="">
              <div class="button start" v-for="(item , index) in row.orderAbnormalMode.tipList" :key="index">
                <i :class="['icon' , 'iconfont', 'unify-dian', stateList[item.abnormalFlag].class]"></i>
                <span> {{item.tipName}} </span>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="button" v-else>
          <i :class="[
              'icon',
              'iconfont',
              'unify-dian',
              stateList[row.orderAbnormalMode.abnormalFlag].class,
            ]"></i>
          <span>
            {{ row.orderAbnormalMode.abnormalStr }}
          </span>
        </div>
      </template>

      <template #info="{ row }">
        <div class="button" v-if="row.infoAbnormalMode === null">-</div>
        <div class="button" v-else-if="row.infoAbnormalMode.tipList && row.infoAbnormalMode.tipList.length > 0">
          <el-popover placement="left" :width="200" trigger="hover">
            <template #reference>
              <div class="flex a-c pointer">
                <i :class="[
                    'icon',
                    'iconfont',
                    'unify-dian',
                    stateList[row.infoAbnormalMode.abnormalFlag].class,
                  ]"></i>
                <span>
                  {{ row.infoAbnormalMode.abnormalStr }}
                </span>
              </div>
            </template>
            <div class="">
              <div class="button start" v-for="(item , index) in row.infoAbnormalMode.tipList" :key="index">
                <i :class="['icon' , 'iconfont', 'unify-dian', stateList[item.abnormalFlag].class]"></i>
                <span> {{item.tipName}} </span>
              </div>
            </div>
          </el-popover>
        </div>

        <div class="button" v-else>
          <i :class="[
              'icon',
              'iconfont',
              'unify-dian',
              stateList[row.infoAbnormalMode.abnormalFlag].class,
            ]"></i>
          <span>
            {{ row.infoAbnormalMode.abnormalStr }}
          </span>
        </div>
      </template>

      <template #link="{ row }">
        <div class="button" v-if="row.urlAbnormalMode === null">
          -
        </div>
        <div class="button" v-else-if="row.urlAbnormalMode.abnormalFlag == 3">
          <el-popover placement="left" :width="600" trigger="hover">
            <template #reference>
              <div class="flex a-c pointer">
                <i :class="[
                    'icon',
                    'iconfont',
                    'unify-dian',
                    stateList[row.urlAbnormalMode.abnormalFlag].class,
                  ]"></i>
                <span>
                  {{ row.urlAbnormalMode.abnormalStr }}
                </span>
              </div>
            </template>
            <div class="table-box">
              <div class="label-box">扫描时间: <span>{{row.testUrlTimeStr}}</span></div>
              <div class="label-title">异常详情</div>
              <el-table :data="row.unifyWebsiteTestResultModels" border max-height="500px">
                <el-table-column minWidth="200" property="name" label="链接名称" />
                <el-table-column minWidth="200" property="url" label="异常链接" />
                <el-table-column minWidth="100" property="resultDetail" label="异常原因" />
              </el-table>
            </div>
          </el-popover>
        </div>
        <div class="button" v-else>
          <i :class="[
              'icon',
              'iconfont',
              'unify-dian',
              stateList[row.urlAbnormalMode.abnormalFlag].class,
            ]"></i>
          <span>
            {{ row.urlAbnormalMode.abnormalStr }}
          </span>
        </div>
      </template>
    </custom-table>

    <!-- 分页 -->
    <div>
      <customPagination v-show="total > 0" :total="total" v-model:page="paramsFilter.pageNum"
        v-model:limit="paramsFilter.pageSize" @pagination="getFindDatabaseAbnormalMonitoringList()" />
    </div>
  </div>
</template>
<script setup name="AbnormalMonitoring">
import { findDatabaseAbnormalMonitoringList } from "@/js/network/main";
import { ref, reactive} from "vue";
import router from "@/router"
import { MessageBox } from "@/js/utils/commonJs";
const loading = ref(false);
const total = ref(0);
const paramsFilter = reactive({
  pageSize: 10, //当前页大小
  pageNum: 1, //当前页
  search: "",
  abnormalSortType:''
});

const stateList = {
  1: {
    name: "正常",
    class: "normal",
  },
  2: {
    name: "警告",
    class: "warning",
  },
  3: {
    name: "异常",
    class: "dangerous",
  },
};

// 正常(1, "正常")
// 警告(2, "警告")
// 异常(3, "异常")

//列表数据
let dataList = ref([]);

const getTable = () => {
  paramsFilter.pageNum = 1;
  getFindDatabaseAbnormalMonitoringList();
};

const getFindDatabaseAbnormalMonitoringList = () => {
  loading.value = true;
  findDatabaseAbnormalMonitoringList(paramsFilter)
    .then((res) => {
      loading.value = false;
      if (!res.success) {
        MessageBox.error(res.msg);
        return;
      }

      dataList.value = res.data;
      total.value = Number(res.count);
    })
    .catch((error) => {
      console.log(error, "catchError");
    });
};

const goDetail = (item) => {
  router.push({
        path: "/dbConfig/detailSetting",
        query: {
          id: item.id,
          dId: item.dId,
          routeType:1
        }
      });
}

const sortChange = (v) => {
  if(paramsFilter.abnormalSortType == v) return
  paramsFilter.abnormalSortType = v;
  getTable();
}

// 列表配置
const tableData = ref([
  // <el-table-column type="index" width="50" />
  {
    label: "数据库",
    minWidth: "140px",
    align: "center",
    prop: "dbName",
    template:"dbName"
  },
  {
    label: "层级",
    width: "120px",
    align: "center",
    prop: "totalFlagStr",
  },
  {
    label: "订购状态监测",
    minWidth: "60px",
    align: "center",
    prop: "role",
    template: "state",
    showOverflowTooltip: false,
    headerTemplate:'stateHeader'
  },
  {
    label: "信息完整性",
    minWidth: "60px",
    align: "center",
    prop: "org",
    template: "info",
    showOverflowTooltip: false,
    headerTemplate: 'infoHeader'

  },
  {
    label: "链接监测",
    minWidth: "60px",
    align: "center",
    prop: "createdTimeStr",
    template: "link",
    showOverflowTooltip: false,
    headerTemplate: 'linkHeader'

  },
]);
//请求数据
getTable();
</script>
<style lang="scss" scoped>
.abnormal-monitoring {
  padding-top: 20px;
  .input-container {
    height: 38px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

    .iconfont {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .input-container:focus-within {
    border: 1px solid #409eff;
  }

  .button-container {
    height: 40px;
    width: 64px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    background: #2054d6;
    border-radius: 0px 2px 2px 0;
  }

  :deep(.el-input__wrapper) {
    box-shadow: none !important;
    background: transparent !important;

    &.is-focused {
      box-shadow: none !important;
    }

    &.is-hovering {
      box-shadow: none !important;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  color: #6573a2;
  &.start{
    justify-content: flex-start;

    &:first-child{
      margin-bottom: 4px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .label {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 4px;
  }

  .icon {
    font-size: 24px;
  }

  .warning {
    color: #ffaf00;
  }
  .dangerous {
    color: #e3080c;
  }
  .normal {
    color: rgb(145, 205, 118);
  }
}
.label-header{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .hdeader-icon{
    font-size: 18px;
    margin-left: 4px;

    &.active{
      color: $primary;
    }
  }
}
.table-box {
  padding: 10px;

  .label-box {
    color: #696969;
    font-size: 14px;
    > span {
      color: #000;
      margin-left: 4px;
    }
  }
  .label-title {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    margin: 8px 0;
  }
}
</style>

<!-- 反馈管理 -->
<template>
  <div class="feedbackManagement">
    <div class="menu flex" style="height: 70px; position: fixed; top: 0">
      <div class="item act">
        <i class="iconfont unify-daohangguanli" />反馈管理
      </div>
    </div>

    <!-- content -->
    <div class="feedback-content view-container">
        <router-view/>
    </div>
  </div>
</template>
<script setup name="FeedbackManagement"></script>
<style lang="scss" scoped>
.feedbackManagement {
  .menu {
    margin-left: 20px;

    .item {
      padding: 24px 0;
      margin-right: 80px;
      cursor: pointer;
      font-size: 15px;
      color: #424655;
      transition: 0.3s ease;
      

      &:hover {
        color: #2054d6;
      }

      .iconfont {
        margin-right: 4px;
      }

      &.act {
        color: #2054d6;
        font-weight: 600;
      }
    }
  }
}
</style>

<template>
  <div class="dbConfig">
    <div class="menu flex" style="height: 70px;position: fixed;top: 0">
      <div class="item act">
        <i class="iconfont unify-daohangguanli" />导航管理
      </div>
      <div class="item" @click="jumpTo('http://data.addup.com.cn/#/databaseManage/orderManage')">
        <i class="iconfont unify-dinggouguanli" />订购管理
      </div>
      <div class="item" @click="jumpTo('http://data.addup.com.cn/#/databaseManage/resourceManage')">
        <i class="iconfont unify-liebiaoguanli" />列表管理
      </div>
      <div class="item" @click="jumpTo('http://data.addup.com.cn/#/databaseManage/reportManage')">
        <i class="iconfont unify-counterguanli" />COUNTER管理
      </div>
      <div class="item" @click="jumpTo('http://data.addup.com.cn/#/databaseManage/cms')">
        <i class="iconfont unify-kushangguanli" />库商协同
      </div>
    </div>
    <div class="view-container">
      <!-- <router-view/> -->
      <router-view v-slot="{ Component }">
        <!-- 'DbNavManage' -->
        <keep-alive :include="[]">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DbConfig',
  props: {},
  created() {

  },
  methods: {
    jumpTo(url){
      window.open(url)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dbConfig {
  .menu {
    margin-left: 20px;

    .item {
      padding: 24px 0;
      margin-right: 80px;
      cursor: pointer;
      font-size: 15px;
      color: #424655;
      transition: 0.3s ease;

      &:hover{
        color: #2054D6;
      }

      .iconfont{
        margin-right: 4px;
      }

      &.act {
        color: #2054D6;
        font-weight: 600;
      }
    }
  }
}

</style>

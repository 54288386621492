//产品
import require from "@/js/network/require.js";
import {handleError} from "@/js/utils/commonJs";


/**
 get
 **/
var findRecommendedBookList = function (params) {
    return require({
        url: '/ersmap-main-server/recommended/findRecommendedBookList' + `?size=${params.size}&datatype=${params.datatype}`,
        method: 'get'
    });
}
/**
 * post
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
var recommendedArticleList = function (params) {
    return require({
        url: '/ersmap-main-server/recommended/recommendedArticleList',
        method: 'post',
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        },
        data: params,
        cancelTicket: params.cancelTicket
    });
}
/**
 * 图片上传
 **/
var uploadHelpCommunityPic = function (params) {
    return require({
        url: `/ersmap-main-server/helpCommunity/uploadHelpCommunityPic`,
        method: 'post',
        data: params,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}
/**
 * Description:分页查询数据库管理列表
 * @param databaseQueryModel: 查询条件
 * @return com.kcidea.common.common.result.PageResult<com.kcidea.main.model.DatabaseModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/29 11:15
 **/
var findDatabaseManageList = function (params) {
    return require.post('main-server/unifyVdatabaseDetails/findDatabaseManageList',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}
var findExposureRecordModelListPage = function (params) {
    return require.post('main-server/exposureRecord/findExposureRecordModelListPage',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}
var findTodayExposureRecordStillValue = function (params) {
    return require.post('main-server/exposureRecord/findTodayExposureRecordStillValue',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}
var findStillResourceStatistics = function (params) {
    return require.get('main-server/exposureRecord/findStillResourceStatistics',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}



/**
 * Description:分页查询数据库管理列表
 * @param databaseQueryModel: 查询条件
 * @return com.kcidea.common.common.result.PageResult<com.kcidea.main.model.DatabaseModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/29 11:15
 **/
var findPromotionUserListPage = function (params) {
    return require.post('main-server/promotionUser/findPromotionUserListPage',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}
/**
 * Description:数据库导航查询条件
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.CodeAggModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/29 13:58
 **/
var findManagerQueryParamList = function () {
    return require.get('main-server/code/findManagerQueryParamList',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:置顶
 * @param id:数据库详情id
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/1 15:33
 **/
var setTopFlag = function (params) {
    return require.get('main-server/unifyVdatabaseDetails/setTopFlag',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:置顶
 * @param id:数据库详情id
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/1 15:33
 **/
var deleteById = function (params) {
    return require.get('main-server/promotionUser/deleteById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:全局设置开关
 * @param unifyGlobalSettingQueryModel:条件
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 10:20
 **/
var updateSystemGlobalSettingBySid = function (params) {
    return require.post('main-server/unifyGlobalSetting/updateSystemGlobalSettingBySid',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询全局设置
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyGlobalSettingModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/26 10:58
 **/
var findOneGlobalSettingBySid = function () {
    return require.get('main-server/unifyGlobalSetting/findOneGlobalSettingBySid',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询学科管员列表
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.common.model.StringNameLongValueModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 10:07
 **/
var findLibrarianList = function () {
    return require.get('/main-server/librarian/findLibrarianList',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询数据库详情:管理端
 * @param id: 数据库详情表id
 * @param dId: 数据库id
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.DatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 11:04
 **/
var findOneManagerDatabaseDetailsById = function (params) {
    return require.get('/main-server/unifyVdatabaseDetails/findOneManagerDatabaseDetailsById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存数据库基本信息:管理端
 * @param databaseBaseInfoAddParamModel: 保存参数
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 14:57
 **/
var saveManagerDatabaseDetailsBaseInfo = function (params) {
    return require.post('/main-server/unifyVdatabaseDetails/saveManagerDatabaseDetailsBaseInfo',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询详情页面公共信息
 * @param id: 数据库详情id
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.DatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 15:47
 **/
var findManagerDatabaseDetailsPublicInfoById = function (params) {
    return require.get('/main-server/unifyVdatabaseDetails/findManagerDatabaseDetailsPublicInfoById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 开启关闭数据库展示
 * @param id: 详情id
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 16:11
 **/
var openDatabase = function (params) {
    return require.get('/main-server/unifyVdatabaseDetails/openDatabase',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存收录年份
 * @param vyear:年份
 * @param id:数据库详情id
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 16:19
 **/
var saveVyearStr = function (params) {
    return require.get('/main-server/unifyVdatabaseDetails/saveVyearStr',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存备忘录
 * @param memorandum: 备忘录内容
 * @param id: 数据库详情id
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 16:24
 **/
var saveMemorandum = function (params) {
    return require.post('/main-server/unifyVdatabaseDetails/saveMemorandum',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:文件上传
 * @param file: 文件
 * @param path: 文件存放路径
 * @return com.kcidea.common.common.result.Result<com.kcidea.common.model.common.IdNameModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2023/11/21 10:44
 **/
var uploadFileByMultipartFile = function (params) {
    return require.post('/main-server/file/uploadFileByMultipartFile',{
        params,
        // contentType:"multipart/form-data"
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:大文件上传
 * @param file: 文件
 * @param path: 文件存放路径
 * @return com.kcidea.common.common.result.Result<com.kcidea.common.model.common.IdNameModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2023/11/21 10:44
 **/
var uploadMultipartFile = function (params) {
    return require.post('/main-server/file/uploadMultipartFile',{
        params,
        // contentType:"multipart/form-data"
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询详情页面公共信息
 * @param id: 数据库详情id
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.DatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 15:47
 **/
var findManagerNavQueryParamList = function () {
    return require.get('/main-server/code/findManagerNavQueryParamList',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询详情页面公共信息
 * @param id: 数据库详情id
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.DatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 15:47
 **/
var findOneUnifyTipSettingByDetailsId = function (params) {
    return require.get('/main-server/unifyTipSetting/findOneUnifyTipSettingByDetailsId',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询详情页面公共信息
 * @param id: 数据库详情id
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.DatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/2 15:47
 **/
var addUnifyTipSetting = function (params) {
    return require.post('/main-server/unifyTipSetting/addUnifyTipSetting',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:站点管理回显列表
 * @param unifyVdatabaseDetailsId: 数据库详情id
 * @return com.kcidea.common.common.result.Result<List<DatabaseUrlModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 15:12
 **/
var findOneUnifyVdatabaseDetailsWebsiteByDetailsId = function (params) {
    return require.get('/main-server/unifyVdatabaseDetailsWebsiteRel/findOneUnifyVdatabaseDetailsWebsiteByDetailsId',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:站点管理保存站点
 * @param databaseUrlAddParamModel: 保存参数
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 15:18
 **/
var addUnifyVdatabaseDetailsWebsite = function (params) {
    return require.post('/main-server/unifyVdatabaseDetailsWebsiteRel/addUnifyVdatabaseDetailsWebsite',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存附件
 * @param unifyAttachmentAddParamModel:保存参数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:23
 **/
var saveAttachment = function (params) {
    return require.post('/main-server/unifyAttachment/saveAttachment',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 删除附件
 * @param unifyAttachmentAddParamModel:保存参数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:23
 **/
var deleteAttachmentById = function (params) {
    return require.get('/main-server/unifyAttachment/deleteAttachmentById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 根据 数据库详情id查询附件
 * @param unifyVdatabaseDetailsId: 数据库详情id
 *
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.main.model.UnifyHelpDocumentModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:12
 **/
var findListByUnifyVdatabaseDetailsId = function (params) {
    return require.get('/main-server/unifyAttachment/findListByUnifyVdatabaseDetailsId',{params}).catch(error=>{
        handleError(error)
    });
}
/**
 * 查看编目日志
 * @param unifyVdatabaseDetailsId: 数据库详情id
 *
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.main.model.UnifyHelpDocumentModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:12
 **/
var findLogListByUnifyVdatabaseDetailsId = function (params) {
    return require.get('/main-server/unifyAttachment/findLogListByUnifyVdatabaseDetailsId',{params}).catch(error=>{
        handleError(error)
    });
}
/**
 * 根据 数据库详情id查询帮助文档
 * @param unifyVdatabaseDetailsId: 数据库详情id
 *
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.main.model.UnifyHelpDocumentModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:12
 **/
var findListByUnifyVdatabaseDetailsId2 = function (params) {
    return require.get('/main-server/unifyHelpDocument/findListByUnifyVdatabaseDetailsId',{params}).catch(error=>{
        handleError(error)
    });
}
/**
 * 管理-数据库详情-导航设置回显
 * @param id: 数据库详情id
 * @param dId: 数据库id
 *
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyVdatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/4 18:39
 **/
var findManagerNavSettingById = function (params) {
    return require.get('/main-server/unifyVdatabaseDetails/findManagerNavSettingById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 子库关联
 * @param id: 数据库详情id
 * @param dId: 数据库id
 *
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyVdatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/4 18:39
 **/
var findChildListByUnifyVdatabaseDetailsId = function (params) {
    return require.get('/main-server/unifySublibrarySort/findChildListByUnifyVdatabaseDetailsId',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存字库
 * @param id: 数据库详情id
 * @param dId: 数据库id
 *
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyVdatabaseDetailsModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/4 18:39
 **/
var saveSort = function (params) {
    return require.post('/main-server/unifySublibrarySort/saveSort',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 管理-数据库详情-导航设置保存接口
 * @param navSettingAddParamModel:
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/2/4 19:39
 **/
var saveManagerNavSetting = function (params) {
    return require.post('/main-server/unifyVdatabaseDetails/saveManagerNavSetting',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存帮助文档
 * @param unifyHelpDocumentAddParamModel:保存参数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/14 15:23
 **/
var saveHelpDocument = function (params) {
    return require.post('/main-server/unifyHelpDocument/saveHelpDocument',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:保存标签
 * @param name: 标签名称
 * @param colour: 颜色
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 14:43
 **/
var addLabel = function (params) {
    return require.get('/main-server/unifyLabel/addLabel',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 根据标签id删除
 * @param id: id
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Boolean>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/25 8:49
 **/
var deleteLabelById = function (params) {
    return require.get('/main-server/unifyLabel/deleteLabelById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * Description:查询版权公告
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyNoticeModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 14:19
 **/
var findOneUnifyNoticeBySid = function () {
    return require.get('/main-server/unifyNotice/findOneUnifyNoticeBySid',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:保存版权公告
 * @param content: 内容
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 14:26
 **/
var updateUnifyNoticeBySid = function (params) {
    return require.post('/main-server/unifyNotice/updateUnifyNoticeBySid',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 分页查询注册用户列表
 * @param query: 查询条件
 *
 * @return com.kcidea.common.common.result.PageResult<com.kcidea.user.model.user.RegisterUserListModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/17 15:18
 **/
var findRegisterUserListPage = function (params) {
    return require.post('main-server/user/findRegisterUserListPage',{
        params,
    }).catch(error=>{
        if (error.message == "Request Canceled!") {
            return Promise.reject(error);
        }
        handleError(error)
    });
}
/**
 * 更新用户禁用状态
 *
 * @param targetId 目标用户ID
 * @return Result<Boolean>
 * @author wangyao
 * @date 2022/03/25 08:51
 **/
var updateUserDisableFlag = function (params) {
    return require.get('main-server/user/updateUserDisableFlag',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 删除用户
 *
 * @param targetId 目标用户ID
 * @return Result<Boolean>
 * @author wangyao
 * @date 2022/03/25 08:51
 **/
var deleteUser = function (params) {
    return require.get('main-server/user/deleteUser',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 分页查询管员列表
 * @param query: 查询条件
 *
 * @return com.kcidea.common.common.result.PageResult<com.kcidea.user.model.user.RegisterUserListModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/17 15:49
 **/
var findLibrarianListPage = function (params) {
    return require.post('/main-server/librarian/findLibrarianListPage',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 回显管员
 * @param id:id
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Boolean>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 9:29
 **/
var findOneLibrarianById = function (params) {
    return require.get('main-server/librarian/findOneLibrarianById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 删除管员
 * @param id:id
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Boolean>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 9:29
 **/
var deleteLibrarianById = function (params) {
    return require.get('main-server/librarian/deleteLibrarianById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 添加编辑馆员
 **/
var addLibrarian = function (params) {
    return require.post('main-server/librarian/addLibrarian',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 新增推广人
 **/
var addPromotionUser = function (params) {
    return require.get('main-server/promotionUser/addPromotionUser',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
var findOneById = function (params) {
    return require.get('main-server/promotionUser/findOneById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 查询馆员
 **/
var findDropdownUserListBySchoolId = function () {
    return require.get('main-server/user/findDropdownUserListBySchoolId',{}).catch(error=>{
        handleError(error)
    });
}
/**
 * 查询卡类型
 *
 * @return com.kcidea.common.common.result.Result<java.util.List<java.lang.String>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 10:55
 **/
var findCardTypeNameList = function () {
    return require.post('/main-server/user/findCardTypeNameList',{}).catch(error=>{
        handleError(error)
    });
}
/**
 * 查询数据库展示列表
 * @param databaseQueryModel: 查询条件
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.main.model.DatabaseModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/30 10:20
 **/
var findAuthorizedDatabaseList = function (params) {
    return require.post('/main-server/unifyVdatabaseDetails/findAuthorizedDatabaseList',{params}).catch(error=>{
        handleError(error)
    });
}
/**
 * 查询数据库展示列表
 * @param databaseQueryModel: 查询条件
 * @return com.kcidea.common.common.result.Result<java.util.List<com.kcidea.main.model.DatabaseModel>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/30 10:20
 **/
var findLibrarianAuthorizedDatabaseList = function (params) {
    return require.post('/main-server/unifyVdatabaseDetails/findLibrarianAuthorizedDatabaseList',{params}).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存已授权数据库
 * @param queryParamModel:保存参数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 16:13
 **/
var saveAuthorizedList = function (params) {
    return require.post('main-server/unifyVdatabaseUserCardTypeRel/saveAuthorizedList',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 保存已授权数据库
 * @param queryParamModel:保存参数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 16:13
 **/
var saveAuthorizedListV2 = function (params) {
    return require.post('main-server/unifyVdatabaseDetailsLibrarianRel/saveAuthorizedList',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 查询卡类型
 *
 * @return com.kcidea.common.common.result.Result<java.util.List<java.lang.String>>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/4/18 10:55
 **/
var findProcessCalendarList = function (params) {
    return require.get('main-server/mapProcess/findProcessCalendarList',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 统计资源数量和注册用户数
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/17 14:23
 **/
var countHomeVdatabaseAndUserNum = function (params) {
    return require.get('main-server/unifyHome/countHomeVdatabaseAndUserNum',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 统计数据库点击量，链接点击量，独立访客，活跃注册用户
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var countClickNum = function (params) {
    return require.get('main-server/unifyVdatabaseDetailsClick/countClickNum',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 根据数据库列表统计
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var findListCountClickNum = function (params) {
    return require.get('main-server/unifyVdatabaseDetailsClick/findListCountClickNum',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 获取路由信息
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var findMenuListBySchoolId = function () {
    return require.get('main-server/unifySchoolMenuRel/findMenuListBySchoolId',{}).catch(error=>{
        handleError(error)
    });
}

/**
 * 根据语种类型统计
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var countByLanguageType = function (params) {
    return require.get('main-server/unifyVdatabaseDetailsClick/countByLanguageType',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 类型分布统计
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var countByType = function (params) {
    return require.get('main-server/unifyVdatabaseDetailsClick/countByType',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 读者卡统计，统计柱状图
 *
 * @return com.kcidea.common.common.result.Result<java.lang.Integer>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/5/24 14:23
 **/
var countByCardTypeName = function (params) {
    return require.get('main-server/unifyVdatabaseDetailsClick/countByCardTypeName',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
var findStillResourceChartStatistics = function (params) {
    return require.get('main-server/exposureRecord/findStillResourceChartStatistics',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 资源推广记录
 **/
var findPromotionRecordListPage = function (params) {
    return require.post('main-server/promotionRecord/findPromotionRecordListPage',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
var findOnePromotionRecordStatistics = function (params) {
    return require.get('main-server/promotionRecord/findOnePromotionRecordStatistics',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 资源推广记录
 **/
var findPromotionUserDropDownList = function (params) {
    return require.get('main-server/promotionUser/findPromotionUserDropDownList',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 资源推广记录
 **/
var findOneAuditDetailById = function (params) {
    return require.get('main-server/promotionRecord/findOneAuditDetailById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 资源推广记录
 **/
var deletePromotionRecordById = function (params) {
    return require.get('main-server/promotionRecord/deletePromotionRecordById',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}

/**
 资源推广记录
 **/
var audit = function (params) {
    return require.get('main-server/promotionRecord/audit',{
        params,
    }).catch(error=>{
        handleError(error)
    });
}
var exportPro = function (params) {
    return require.get('main-server/promotionRecord/export',{
        params,
        responseType: "blob",
    }).catch(error=>{
        handleError(error)
    });
}
var exportPost = function (params) {
    return require.post('main-server/exposureRecord/exportPost',{
        params,
        responseType: "blob",
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 达人招募 审核端数量
 * **/ 
var countAuditNum = function (params) {
    return require.get('main-server/promotionUserRecruitRecord/countAuditNum',{
        params
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 达人招募 code
 * **/ 
var getAuthorizeCode = function (params) {
    return require.get('main-server/promotionUserRecruitRecord/getAuthorizeCode',{
        params
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 达人招募 列表
 * **/ 
var findRecruitRecordListPage = function (params) {
    return require.post('main-server/promotionUserRecruitRecord/findRecruitRecordListPage',{
        params
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * 达人招募 审核获取详情
 * **/ 
var recruitRecordFindOneAuditDetailById = function (params) {
    return require.get('main-server/promotionUserRecruitRecord/findOneAuditDetailById',{
        params
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 达人招募 审核
 * **/ 
var promotionUserRecruitRecordAudit = function (params) {
    return require.get('main-server/promotionUserRecruitRecord/audit',{
        params
    }).catch(error=>{
        handleError(error)
    });
}

// 

var findHomeWaterfallFlow = function (params) {
    return require.get('main-server/unifyHome/findHomeWaterfallFlow',{
        params
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 异常列表
 * **/ 
var findDatabaseAbnormalMonitoringList = function (params) {
    return require.post('main-server/unifyVdatabaseDetails/findDatabaseAbnormalMonitoringList',{
        params
    }).catch(error=>{
        handleError(error)
    });
}

/**
 * 反馈列表
 * **/ 
var findFeedbackManageList = function (params) {
    return require.post('main-server/feedback/findFeedbackManageList',{
        params
    }).catch(error=>{
        handleError(error)
    });
}

// 
/**
 * 反馈详情
 * **/ 
var findOneFeedbackDetail = function (params) {
    return require.post('main-server/feedback/findOneFeedbackDetail',{
        params
    }).catch(error=>{
        handleError(error)
    });
}






export {
    findDatabaseManageList,
    findExposureRecordModelListPage,
    findTodayExposureRecordStillValue,
    findStillResourceStatistics,
    findPromotionUserListPage,
    findManagerQueryParamList,
    setTopFlag,
    deleteById,
    updateSystemGlobalSettingBySid,
    findOneGlobalSettingBySid,
    findLibrarianList,
    findOneManagerDatabaseDetailsById,
    saveManagerDatabaseDetailsBaseInfo,
    findManagerDatabaseDetailsPublicInfoById,
    openDatabase,
    saveVyearStr,
    saveMemorandum,
    uploadFileByMultipartFile,
    uploadMultipartFile,
    findManagerNavQueryParamList,
    findOneUnifyTipSettingByDetailsId,
    addUnifyTipSetting,
    findOneUnifyVdatabaseDetailsWebsiteByDetailsId,
    findListByUnifyVdatabaseDetailsId,
    findListByUnifyVdatabaseDetailsId2,
    findLogListByUnifyVdatabaseDetailsId,
    addUnifyVdatabaseDetailsWebsite,
    saveAttachment,
    deleteAttachmentById,
    findManagerNavSettingById,
    findChildListByUnifyVdatabaseDetailsId,
    saveSort,
    saveManagerNavSetting,
    saveHelpDocument,
    addLabel,
    deleteLabelById,
    findOneUnifyNoticeBySid,
    updateUnifyNoticeBySid,
    findRegisterUserListPage,
    updateUserDisableFlag,
    deleteUser,
    findLibrarianListPage,
    deleteLibrarianById,
    findOneLibrarianById,
    addLibrarian,
    addPromotionUser,
    findOneById,
    findDropdownUserListBySchoolId,
    findCardTypeNameList,
    findAuthorizedDatabaseList,
    findLibrarianAuthorizedDatabaseList,
    saveAuthorizedList,
    saveAuthorizedListV2,
    findProcessCalendarList,
    countHomeVdatabaseAndUserNum,
    countClickNum,
    findListCountClickNum,
    findMenuListBySchoolId,
    countByLanguageType,
    countByType,
    countByCardTypeName,
    findStillResourceChartStatistics,
    findPromotionRecordListPage,
    findOnePromotionRecordStatistics,
    findPromotionUserDropDownList,
    findOneAuditDetailById,
    deletePromotionRecordById,
    audit,
    exportPro,
    exportPost,
    countAuditNum,
    getAuthorizeCode,
    findRecruitRecordListPage,
    recruitRecordFindOneAuditDetailById,
    promotionUserRecruitRecordAudit,
    findHomeWaterfallFlow,
    findDatabaseAbnormalMonitoringList,
    findFeedbackManageList,
    findOneFeedbackDetail
};
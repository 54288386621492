<template>
  <div class="baseInfo">
    <el-form ref="ruleForm" :model="state.helpDocumentList" label-width="auto">
      <div class="main-row flex a-c" v-for="(item, index) in state.helpDocumentList" :key="index">
        <!-- prop=':rules='state.rules[index].name'' -->
        <el-form-item  class="row-tip" style="margin-right: 20px" label="" :prop='`state.helpDocumentList.${index}.name`'  >
          <el-input v-model="state.helpDocumentList[index].name" 
            show-word-limit
            maxlength="100"
            placeholder="请输入文档名称" />
        </el-form-item>
        <!-- :rules='state.rules[index].url' -->
        <el-form-item class="row-content" label="" style="margin-right: 20px" :prop='`state.helpDocumentList.${index}.url`' >
          <el-input  v-model="state.helpDocumentList[index].url" 
            show-word-limit
            maxlength="500"
            placeholder="请输入访问地址" />
        </el-form-item>

        <el-form-item class="row-tip" label=""  prop='state.helpDocumentList[index].permission'>
          <el-select
            v-model="state.helpDocumentList[index].permission"
            multiple
            collapse-tags
            placeholder="请选择下载权限"
          >
            <el-option
              v-for="val in permissionList"
              :key="val.id"
              :label="val.value"
              :value="val.id"
            />
          </el-select>
        </el-form-item>
        <span class="iconfont-container">
          <i
            class="iconfont one unify-shanchu"
            @click="deleteOne(index)"
            v-if="index != 0"
          ></i>
        </span>
      </div>
      <!-- <div
        class="main-row flex"
        v-for="(item, index) in state.helpDocumentList"
      >
        <div class="row-tip" style="margin-right: 20px">
          <el-input
            v-model="state.helpDocumentList[index].name"
            show-word-limit
            maxlength="100"
            placeholder="请输入文档名称"
          />
        </div>
        <div class="row-content" style="padding-right: 50px">
          <el-input
            v-model="state.helpDocumentList[index].url"
            show-word-limit
            maxlength="500"
            placeholder="请输入访问地址"
          />
          <span class="iconfont-container">
            <i
              class="iconfont one unify-shanchu"
              @click="deleteOne(index)"
              v-if="index != 0"
            ></i>
          </span>
        </div>
      </div> -->
    </el-form>

    <div class="add-tip">
      <span @click="addLinkArr">添加一份帮助文档</span>
    </div>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" @click="reset" plain>重置</el-button>
      </div>
    </el-affix>
  </div>
</template>

<script>
import {
  findListByUnifyVdatabaseDetailsId2,
  saveHelpDocument,
  findCardTypeNameList
} from "@/js/network/main/index.js";
import { reactive, ref  , useRef , computed } from "vue";
import { useRoute } from "vue-router";
import { MessageBox } from "@/js/utils/commonJs";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      helpDocumentList: [],
      rules:[]
    });

    const ruleForm = ref(null)

    const permissionList = reactive([]);

    // state.rules = computed(()=>{
    //   let rules = state.helpDocumentList.map((item) => {
    //     if(item.name){
    //       return {
    //         'name':{ required: true, message: '该输入项为必填项!1', trigger: 'blur' }, //校验
    //         'url':{required: true, message: '该输入项为必填项!1', trigger: 'blur'}
    //       }
    //     }else{
    //       return {
    //         'name':'', //校验
    //         'url':''
    //       }
    //     }
        
    //   });
      
    //   return rules

    // })

    let paramsCopy = [];
    const addLinkArr = function () {
      state.helpDocumentList.push({
        name:'',
        url:'',
        permission:['999']
      });

    };
    const deleteOne = function (index) {
      state.helpDocumentList.splice(index, 1);
    };
    const init = function () {
      getFindCardTypeNameList();

      let { id } = route.query;
      findListByUnifyVdatabaseDetailsId2({ unifyVdatabaseDetailsId: id })
        .then((res) => {
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          if (res && res.data && res.data.length > 0) {
            state.helpDocumentList = res.data?.map(val => {
              return {
                ...val,
                permission:val.permission?.length ? val.permission.split(',') : []
              }
            });
            paramsCopy = JSON.parse(JSON.stringify(res.data));
          } else {
            state.helpDocumentList = [{
              name:'',
              url:'',
              permission:['999']
            }];
            paramsCopy = [{}];
          }
        })
        .catch((error) => {
          //MessageBox.error(error);
          console.log(error, "catchError");
        });
    };
    // 权限列表
    const getFindCardTypeNameList = () => {
      findCardTypeNameList().then(res => {
        if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          let typeNameList = res.data?.length ? res.data.map(val => {
            return {
              id:val,
              value:val,
            }
          }) : [];

          typeNameList.unshift({
            id:'999',
            value:'全部可下载'
          })
          permissionList.push(...typeNameList);
      })
    }
    const submit = () => {
      // console.log(ruleForm.value , 'dddd');
      // ruleForm.value.validate((valid) => {
          // if (valid) {

          // 写个循环校验
          let tips = "";
          state.helpDocumentList.forEach(val => {
            if(val.name){
              if(!val.url || !val.permission?.length > 0){
                tips = tips ? val.name + '信息不完整！' :  val.name + '信息不完整！'
              }
            }
          })

          tips = tips ? `<div style='color:#E6A23C'>${tips}不完整的信息将不会提交，确定要继续提交吗？</div>` : "确定要执行本操作吗?"

            ElMessageBox.confirm(tips , {
              dangerouslyUseHTMLString: true,
            })
            .then((aaa) => {
              if (aaa) {
                let { id, dId } = route.query;
              
                let data = JSON.parse(JSON.stringify(state.helpDocumentList));
                data = data?.filter( val => {
                  return val.name && val.url && val.permission?.length
                })
                data = data?.map(val => {
                  return {
                    ...val,
                    permission:val.permission.join(',')
                  }
                })
              
                saveHelpDocument({
                  helpDocumentList: data,
                  unifyVdatabaseDetailsId: id,
                })
                  .then((res) => {
                    if (!res.success) {
                      MessageBox.error(res.msg);
                      return;
                    }
                    MessageBox.success("保存成功！");
                    window.location.reload();
                  })
                  .catch((error) => {
                    //MessageBox.error(error);
                    console.log(error, "catchError");
                  });
              }
            })
          //   .catch(() => {});
          // } else {
          //   MessageBox.warning("请填写必填项")
          // }
      // })

      
    };
    const reset = () => {
      let data = JSON.parse(JSON.stringify(paramsCopy));
      state.helpDocumentList = data?.map(val => {
              return {
                ...val,
                permission:val.permission?.length ? val.permission.split(',') : []
              }
            });;
    };
    init();
    return {
      state,
      reset,
      submit,
      addLinkArr,
      deleteOne,
      permissionList,
      ruleForm
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep(.el-form-item){
  margin-bottom: 0px;
}
.main-row {
  margin-bottom: 8px;
}

.button-container {
  padding: 10px 0;
  background-color: white;
}

.row-tip {
  // width: 280px;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.row-content {
  // width: 400px;
  flex: 2;
  position: relative;
  box-sizing: border-box;

  .iconfont {
    cursor: pointer;
  }
  // .iconfont-container {
  //   position: absolute;
  //   right: -10px;
  //   top: 10px;
  // }
  .one {
  }
  .two {
    margin-left: 15px;
  }
}

.iconfont-container{
  width: 34px;
  flex-shrink: 0;
  text-align: right;
}

.add-tip {
  font-size: 14px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
  margin-bottom: 25px;
}
</style>

<template>
  <div class="baseInfo">
    <div class="main-row">
      <div class="row-tip flex a-c j-s" style="margin-bottom: 20px">
        特别提示
        <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.specialFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <tinyMceEditor ref="specialContent" :defaultId="'specialContent'" key="specialContent"
                     @update:modelValue="(val)=>updateContent('specialContent',val)"/>
    </div>
    <div class="main-row">
      <div class="row-tip flex a-c j-s" style="margin-bottom: 20px">
        重要提示
        <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.importantFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <tinyMceEditor ref="importantContent" :defaultId="'importantContent'" key="importantContent"
                     @update:modelValue="(val)=>updateContent('importantContent',val)"/>
    </div>
    <div class="main-row">
      <div class="row-tip flex a-c j-s" style="margin-bottom: 20px">
        故障提示
        <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.faultFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <tinyMceEditor ref="faultContent" :defaultId="'faultContent'" key="faultContent"
                     @update:modelValue="(val)=>updateContent('faultContent',val)"/>
    </div>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" plain @click="reset">重置</el-button>
      </div>
    </el-affix>
  </div>
</template>

<script>
import {
  findOneUnifyTipSettingByDetailsId,
  addUnifyTipSetting
} from "@/js/network/main/index.js"
import tinyMceEditor from "@/components/untils/tinyMceEditor"
import { onMounted, reactive, ref} from "vue";
import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";

export default {
  components: {
    tinyMceEditor
  },
  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();
    const specialContent = ref("");
    const importantContent = ref("");
    const faultContent = ref("");
    let state = reactive({
        params:{
          specialContent:"",
          importantContent:"",
          faultContent:"",
          faultFlag:0,
          importantFlag:0,
          specialFlag:0
        },
      paramsCopy:{

      }
    });
    let stateCopy = {};
    const beforeChange = function () {
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
          if (aaa) {
            resolve(true);
          }
          reject(false);
        }).catch(() => {
          reject(false);
        })
      })

    }
    const updateContent = (key, val) => {
      console.log('key, val: ', key, val);
      state.params[key] = val;
    }
    const init = function () {
      let {id} = route.query
      findOneUnifyTipSettingByDetailsId({unifyVdatabaseDetailsId:id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (res && res.data) {
          state.params = {...res.data};
          state.paramsCopy = {...res.data};
          if(res.data.specialContent){
            specialContent.value.handleSetContent(res.data.specialContent);
          }

          if(res.data.importantContent){
            importantContent.value.handleSetContent(res.data.importantContent);
          }

          if(res.data.faultContent){
             faultContent.value.handleSetContent(res.data.faultContent);

          }
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let {id, dId} = route.query
          let data = {...state.params,unifyVdatabaseDetailsId:id};
          console.log('data: ', data);
          addUnifyTipSetting(data).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！");
            window.location.reload();
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error,"catchError");
          });
        }
      }).catch(() => {
      })

    }
    const reset = () => {
      state.params = JSON.parse(JSON.stringify(state.paramsCopy));
      specialContent.value = state.paramsCopy.specialContent;
      importantContent.value = state.paramsCopy.importantContent;
      faultContent.value = state.paramsCopy.faultContent;
    }
    onMounted(()=>{
      setTimeout(()=>{
        init();
      },400)

    })
    return {
      state,
      specialContent,
      importantContent,
      faultContent,
      beforeChange,
      updateContent,
      submit,
      reset
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-row {
  margin-bottom: 25px;
}

.button-container {
  padding: 10px 0;
  background-color: white;
}

.row-tip {
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.input-container {
  width: 600px;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

:deep(.avatar-uploader .el-upload) {
  border: 1px dashed var(--el-border-color) !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

</style>
